import { BehaviorSubject } from 'rxjs';

class DataManager {
    constructor() {

        this.selectedSites = new BehaviorSubject([]);

        if (DataManager.instance) {
            return DataManager.instance;
        }
        this.data = {
            savedSearchQuery: null,
            savedSearchFilters: null,
            savedSortBy: null,
            filteredResults: [],
            searchQuery: "",
            allSitesPressed: false,
            pageSize: 20,
            pageIndex: 1,
            totalDevices: 0,
            totalPages: 0,
            getAllDevices: 0,
            online: 0, 
            offline: 0, 
            noTag: 0, 
            sort: -1,
            categoryFilterLength: 0,
            sortByLength: 0,
            categoryFilter: [],
            sortBy: [],
            deviceUUIDs: [],
            preFilteredEvents: [],
            dateRange: [],
            groupName: "", 
            selectedGroup: "",
            groupClicked: "",
            groupClickedID: "",
            userToInvite: "",
            isGroupMode: false,
            //selectedSites: [],
            viewType: 0,
            groups: [],
            group_path: "",
            isChecked: false,
        };

        DataManager.instance = this;
        //Object.freeze(DataManager.instance);
    }

    // Method to set data
    setItem(key, value) {
        if (this.data.hasOwnProperty(key)) {
            this.data[key] = value;
        } else {
            console.warn(`Key ${key} is not a valid data property.`);
        }
    }

    // Method to get data
    getItem(key) {
        if (this.data.hasOwnProperty(key)) {
            return this.data[key];
        } else {
            console.warn(`Key ${key} is not a valid data property.`);
            return null;
        }
    }

    // Method to clear specific data
    clearItem(key) {
        if (this.data.hasOwnProperty(key)) {
            this.data[key] = [];
        } else {
            console.warn(`Key ${key} is not a valid data property.`);
        }
    }

    addSite(site) {
        const currentSites = this.selectedSites.value;
        this.selectedSites.next([...currentSites, site]);
    }
    
    removeSite(site) {
        const currentSites = this.selectedSites.value.filter(s => s !== site);
        this.selectedSites.next(currentSites);
    }

    setSelectedSites(sites) {
        this.selectedSites.next(sites); // Update observable
        this.data.selectedSites = sites; // Keep internal data consistent
      }

    // Method to clear all data
    clearAll() {
        this.data = {
            savedSearchQuery: null,
            savedSearchFilters: null,
            savedSortBy: null,
            filteredResults: [],
            searchQuery: "",
            allSitesPressed: false,
            pageSize: 20,
            pageIndex: 1,
            totalDevices: 0,
            totalPages: 0,
            deviceUUIDs: [],
            preFilteredEvents: [],
            dateRange: [],
            groupName: "", 
            selectedGroup: "",
            groupClicked: "",
            groupClickedID: "",
            userToInvite: "",
            isGroupMode: false,
            //selectedSites: [],
            viewType: 0,
            groups: [],
            group_path: "",
            isChecked: false,
        };
    }
}
;
export default DataManager;
