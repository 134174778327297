import React, { useState, useReducer, useRef, useEffect, useImperativeHandle, forwardRef } from "react";


import CustomDropDown from "../CustomDropDown/CustomDropDown";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import classes from './InviteUserForm.module.css';

import ToggleInput from "../UI/ToggleInput";
import Input from "../CustomDropDown/Input/Input";
import Button1 from "../UI/Button/Button1";
import { copyIPIOSettings, inviteUser } from "../../Api Methods/Api";
import DataManager from "../../hooks/DataManager";


const inputReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: state.value.length > 0 };
    }
    //otherwise 
    return { value: '', isValid: false };
}

const InputFormCreateGroup = ({ label, setName }) => {

    const dataManager = new DataManager();

    const [inputState, dispatchInput] = useReducer(inputReducer, {
        value: '',
        isValid: null
    });

    const inputInputRef = useRef();

    const inputChangeHandler = (event) => {
        dispatchInput({ type: 'USER_INPUT', val: event.target.value });
        console.log(event.target.value)
        setName(event.target.value);
        dataManager.setItem('groupName', event.target.value);
        dataManager.setItem('userToInvite', event.target.value);
    }

    const validateInputHandler = () => {
        dispatchInput({ type: 'INPUT_BLUR' });
    }

    const onClearHandler = () => {
        dispatchInput({ type: 'INPUT_CLEAR', val: "" })
    }

    return (
        <ul className={classes["inputs-list"]}>
            <div className={classes.label}>{label}</div>
            <li className={classes["input-container"]}>
                <Input
                    className={classes.input}
                    ref={inputInputRef}
                    id="Input"
                    type={"text"}
                    isValid={true}
                    value={inputState.value}
                    onChange={inputChangeHandler}
                    onBlur={validateInputHandler}
                />
            </li>
        </ul>
    )
}

export default InputFormCreateGroup;

