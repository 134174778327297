import React, { Children, useEffect, useRef, useState } from 'react'
import classes from "./GroupUserCardContentLayout.module.css";
import ActiveNotificationBellIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Notification - On.svg";
import InactiveNotificationBellIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Notification - Off.svg";
import installerActive from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/installer-on.png";
import installerInactive from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/installer off.png";
import SettingsCog from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Admin - Settings.svg"
import arrowIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Page - Forward.svg";
import { Link, useNavigate } from "react-router-dom";
import { authActions } from '../../store/auth-Slice';
import { useDispatch } from 'react-redux';
import DropdownList from '../../components/UI/Cards/DropdownList';
import useClickOutside from '../../components/hooks/useClickOutside';
import DeleteIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/deleteGroup.png'
import Modal from '../../components/Modal/Modal';
import Button1 from '../../components/UI/Button/Button1';
import { deleteGroupUser } from '../../Api Methods/Api';


const GroupUserCardContentLayout = ({ cardData, apiData, update, setUpdate }) => {
console.log(cardData);
    const [userData, setUserData] = useState(cardData);
    useEffect(() => {
        setUserData(cardData);
    }, [cardData])

    const navigate = useNavigate();
    const dispatch = useDispatch();

    //set user values here from cardData
    const userID = cardData.userID;
    const isAdmin = cardData.groupOwner;
    const name = cardData.fullName;
    const email = cardData.email;

    const [modalTitle, setModalTitle] = useState("title")
    const [modalComponent, setModalComponent] = useState()

    const ModalDialog = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();
    }

    const HandleNavigateToUsersDevices = (UserID, fullName) => {
        sessionStorage.setItem('ClickedUserID', UserID);
        sessionStorage.setItem('ClickedUserFullName', fullName);

        dispatch(authActions.setClickedUserDetails({ UserID, fullName }))

        navigate("/Dashboard/Users/UserSites");
    }

    const [openDropdownRowId, setOpenDropdownRowId] = useState(null);

    const NotificationCounterRef = useRef("NotificationCounter");
    const buttonRef = useRef("DeviceCardSettingCog");
    const dropdownRef = useRef("DropDownMenu");

    useClickOutside(NotificationCounterRef, buttonRef, dropdownRef, () => {
        setOpenDropdownRowId(null);
    });

    const HandleDelete = (userID) => {
        console.log(userID)
        setModalTitle("Remove user?")
        setModalComponent(
            <div>
              <div style={{ textAlign: "center" }}>
                <h2>Are you sure you want to remove this user?</h2>
              </div>
              <br />
              <div style={{ 
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center", 
                gap: "10px" // Adds space between buttons
              }}>
                <Button1 onClick={HandleCloseModal}>No, cancel</Button1>
                <Button1 onClick={() => DeleteUser(userID)}>Yes, I'm sure</Button1>
              </div>
            </div>
          );

          HandleShowModal();
    }

    const DeleteUser = async (userID) => {
        // do API
        console.log(userID)
        const response = await deleteGroupUser(apiData, userID, dispatch);
        if (response){
            console.log(response)
            setUpdate(update+1);
            HandleCloseModal();
        }
    }

    return (
        <>
        <Modal ref={ModalDialog} title={modalTitle} modalName={"Site View"} HandleShowModal={HandleShowModal}>
            {modalComponent}
        </Modal>
        <div className={classes.GroupCardContentLayout}>
            <div className={classes["top-wrapper"]}>
                <div
                    className={classes["siteDetails-layout"]}
                    onClick={() => HandleNavigateToUsersDevices(userID, name)}>
                    <h3>{name}</h3>
                    <h3 style={{ fontWeight: "400" }} className={classes.tagName}>
                        {email}
                    </h3>
                    <br/>
                    <h3 style={{ fontWeight: "400" }} className={classes.tagName}>
                        {isAdmin == 1 ? "Administrator" : "User"}
                    </h3>
                </div>
                <div className={classes["icons-container"]}>
                    <img className={classes.Icon} src={DeleteIcon} 
                    onClick={() => HandleDelete(userID)} />
                </div>
            </div>
        </div>
        </>
    );
}

export default GroupUserCardContentLayout;

